import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";
import BodyText from "lib/@cms/components/shared/BodyText";
import Divider from "lib/@cms/components/primitive/Divider";

function Disclosure() {
  return (
    <Page pathname="disclosure">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.GRAYSCALE}
              data={data.PageHeaderGradients}
            />

            <div className="tw-container">
              <Text variant={Text.variant.SIMPLE}>{data.MetaData.description}</Text>
              <div className="tw-mt-24">
                {data.TextsLists?.map((item, index) => {
                  return (
                    <React.Fragment key={`text_list_${index}`}>
                      <BodyText data={item} variant={BodyText.variant.DECORATION_NONE} />
                      <Divider className="tw-my-7" />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default Disclosure;
